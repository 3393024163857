import 'antd/dist/reset.css';
import styled from "styled-components";
import { useLoginMutation, useAskForPasswordResetMutation, useRenewLoginTokenMutation, useLoginHarryMutation } from 'graphql/User/UserMutations'
import Icon, { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, notification, Layout, Spin, Form, Divider } from 'antd';
import { isArray } from 'util';
import FireflyLogo from 'assets/firefly_logo_horizontal.png'
import User, { AuthPayload, UserContext } from 'graphql/User/User';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import Bugsnag from "@bugsnag/js";
import MicrosoftLogo from "assets/MicrosoftLogo";
import { ROUTES } from 'pages/Root/PagesController';
import PageLoadingPage from 'pages/Root/PageLoadingPage';

const { Header, Content } = Layout;
// styles
const LoginDiv = styled.div`
  padding: 17px;
  width: 350px;
  text-align: center;
  background-color: white;
`;

interface Props {
  onSuccessfulLogin: (authPayload: AuthPayload, options?: { redirectTo?: string, isHarryLogin?: boolean }) => void
  isRefreshingUserToken?: boolean
  harryLoginToken?: string
  harryLoginClientPhone?: string
  harryLoginClientName?: string
  harryLoginClientMessage?: string
}

const LoginFormNoWrap = (props: Props) => {
  const { onSuccessfulLogin, harryLoginToken, harryLoginClientPhone, harryLoginClientMessage, harryLoginClientName } = props
  document.title = "Login - " + (process.env.REACT_APP_HARRY_BUILD ? "Dashboard Harry" : "Dashboard Firefly");
  const { user } = useContext(UserContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isTryingLogin, setIsTryingLogin] = useState(false);
  const [loading, setLoading] = useState(false)

  const [loginMutation] = useLoginMutation({
    variables: { email, password },
    update: (proxy, mutationResult) => {
      //console.log(mutationResult);
      // setIsTryingLogin(false)
      if (mutationResult.data.login)
        onSuccessfulLogin(mutationResult.data.login)
      else
        console.log("Login not successful")
    },
  });

  const [loginHarryMutation] = useLoginHarryMutation({
    variables: { token: harryLoginToken },
    update: (proxy, mutationResult) => {
      //console.log(mutationResult);
      // setIsTryingLogin(false)

      const user = new User(mutationResult.data.loginHarry.user)

      const externalChatChannelIds = user.getAllExternalChatChannelsIds().join(',')
      const redirectToUrl = ROUTES.chatOnlineExternalChatChannel.getUrl(externalChatChannelIds)

      const clientPhone = harryLoginClientPhone?.replace(/[^0-9]/g, '')
      const clientName = harryLoginClientName
      const clientMessage = harryLoginClientMessage

      const redirectTo = redirectToUrl +
        (clientPhone ? `?clientPhone=${clientPhone}` : '') +
        (clientName ? `&clientName=${clientName}` : '') +
        (clientMessage ? `&clientMessage=${clientMessage}` : '')

      if (mutationResult.data.loginHarry)
        onSuccessfulLogin(mutationResult.data.loginHarry, { redirectTo, isHarryLogin: true })
      else
        console.log("Login not successful")
    },
  });

  const [renewTokenMutation] = useRenewLoginTokenMutation();

  /** used to fetch complete user from the login token string */
  const renewToken = async (token: string) => {
    try {
      const res = await renewTokenMutation({ variables: { token } })

      if (res.data) {
        // remove all the search params from the url
        window.history.replaceState(null, null, window.location.pathname)
        onSuccessfulLogin(res.data?.renewLoginToken)
      }

    } catch (e: any) {
      notification.error({ message: 'Erro de login', description: String(e) })
    }
  }

  useEffect(() => {
    // if authToken is specified in url params, fetch complete user info and save it in local storage
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const authToken = params.get('authToken')

    if (authToken)
      renewToken(authToken)
    // eslint-disable-next-line
  }, [])

  const openLoginErrorNotification = (message: string, description: string) => {
    notification.open({
      message,
      description,
    });
  };

  const doLogin = useCallback(async (isHarryLogin?: boolean) => {
    setIsTryingLogin(true)

    try {
      if (isHarryLogin)
        await loginHarryMutation()
      else
        await loginMutation()

    } catch (e: any) {
      Bugsnag.notify(e, (event) => event.setUser(user?.id, user?.email, user?.name));
      if (isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].extensions && e.graphQLErrors[0].extensions.code === "LoginInvalidError") {
        openLoginErrorNotification('Erro no login', (e.graphQLErrors[0].message || ''))
      } else {
        openLoginErrorNotification('Problema técnico', 'Ocorreu um problema técnico. Por favor tente novamente mais tarde. ' + e.message)
      }
      // console.log(JSON.stringify(e))
      // console.log(e.graphQLErrors[0])
    } finally {
      setTimeout(() => {
        setIsTryingLogin(false);
      }, 1000)
    }
  }, [loginHarryMutation, loginMutation, user?.email, user?.id, user?.name])

  useEffect(() => {
    if (harryLoginToken)
      doLogin(true)
  }, [doLogin, harryLoginToken])

  const [resetPasswordMutation] = useAskForPasswordResetMutation({
    variables: { email },
    update: (proxy, mutationResult) => {
      //console.log(mutationResult);

      if (mutationResult.data.askForPasswordReset) {
        openLoginErrorNotification('Redefinição de senha', 'O link para redefinição de senha foi enviado para ' + email)
        //props.onSuccessfulLogin(mutationResult.data.changePassword)
      } else
        console.log("Password change not successful")
    },
  });

  const doForgotPassword = async () => {
    setLoading(true)

    if (!email) {
      openLoginErrorNotification('Erro para redefinição de senha', 'Campo de usuário não preenchido')
      setLoading(false)
      return
    }

    try {
      await resetPasswordMutation()
      //openLoginErrorNotification('Redefinição de senha', 'O link para redefinição de senha foi enviado para '+ email)

    } catch (e: any) {
      Bugsnag.notify(e, (event) => event.setUser(user.id, user.email, user.name));
      if (isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].extensions && e.graphQLErrors[0].extensions.code === 'LoginInvalidError')
        openLoginErrorNotification('Erro para redefinição de senha', 'Não existe um usuário com esse login/e-mail')
      else
        openLoginErrorNotification('Problema técnico', 'Ocorreu um problema técnico. Por favor tente novamente mais tarde. ' + e.message)

    } finally {
      setLoading(false)
    }
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value.trim()
    // props.form.setFieldsValue({ email: newEmail });
    setEmail(newEmail)
  }
  const [form] = Form.useForm();
  return (
    (process.env.REACT_APP_HARRY_BUILD && isTryingLogin) ?
      <div style={{ width: '100vw', height: '100vh' }}>
        <PageLoadingPage />
      </div>
      :
      <Layout style={{ height: '100vh' }}>
        <Header>
          {!process.env.REACT_APP_HARRY_BUILD &&
            <img src={FireflyLogo} alt="FireFly logo" style={{ height: '42px' }} />}
        </Header>
        <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <LoginDiv>
              <Spin spinning={props.isRefreshingUserToken || loading}>
                <h2>Login</h2>
                <Form form={form} name='LoginForm' onFinish={(e) => { doLogin() }} className="login-form">
                  <Form.Item name='email' rules={[
                    { required: true, message: 'Por favor inserir o email' },
                    { type: 'email', message: 'Email inválido' },
                  ]}>
                    <Input
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Usuário"
                      onChange={handleEmailChange} />
                  </Form.Item>

                  <Form.Item name='password' rules={[
                    { required: true, message: 'Por favor inserir a senha' }
                  ]}>
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" size='large' htmlType="submit" className="login-form-button" disabled={isTryingLogin} loading={isTryingLogin}>
                      Entrar
                    </Button>

                    {!process.env.REACT_APP_HARRY_BUILD &&
                      <div
                        style={{ color: "blue", cursor: "pointer", marginTop: '20px' }}
                        onClick={doForgotPassword}
                      >
                        Esqueci minha senha
                      </div>}
                  </Form.Item>
                </Form>
              </Spin>

              <Divider />

              {!process.env.REACT_APP_HARRY_BUILD &&
                <Button
                  size='large'
                  icon={<Icon component={MicrosoftLogo} />}
                  href={process.env.REACT_APP_MICROSOFT_LOGIN_PAGE}
                >
                  Login com microsoft
                </Button>}

              {/* <Button icon={<ReloadOutlined />}>Login com microsoft</Button> */}
            </LoginDiv>
          </div>
        </Content>
        {/* <Footer>Footer</Footer> */}
      </Layout>
  );
}

const LoginForm = LoginFormNoWrap

export default LoginForm;
